function shareToFacebook() {
  openInNewTab('https://www.facebook.com/sharer.php?u=nebertenamsvobodu.cz')
}

function shareToTwitter() {
  openInNewTab('https://twitter.com/share?url=https://nebertenamsvobodu.cz')
}

function openInNewTab(url) {
  let win = window.open(url, '_blank');
  win.focus();
}

function initFyzickaFormProcessingHandler() {
  $('#form-fyzicka').submit(function(event) {

    // get the form data
    // there are many ways to get this data using jQuery (you can use the class or id also)
    let formData = {
      'type': 'fyzicka',
      'firstName': $('input[name=firstName]').val(),
      'lastName': $('input[name=lastName]').val(),
      'email': $('input[name=email]').val(),
      'jsem': $('input[name=jsem]:checked')[0]?.value,
      'gdprSouhlas': $('input[name=gdprSouhlas]:checked')[0]?.checked?.toString() || false,
      'newsletter': $('input[name=newsletter]:checked')[0]?.checked?.toString() || false,
      'token': $('#fyzickaToken').val(),
      'action': $('#fyzickaAction').val(),
    };

    console.log(formData)

    // process the form
    $.ajax({
      type        : 'POST', // define the type of HTTP verb we want to use (POST for our form)
      url         : 'formProcess.php', // the url where we want to POST
      data        : formData, // our data object
      dataType    : 'json', // what type of data do we expect back from the server
      encode          : true
    })
      .done(function(data) {
        console.log(data);
        refreshPetitionSignaturesCount()
        $('form').trigger("reset")
        showSuccessAlert()
      })
      .fail(function(data) {
        console.log(data)
        $('form').html('<div class="alert alert-danger">Nastala chyba při spojení se serverem. Zkuste to prosím později.</div>');
      })

    // stop the form from submitting the normal way and refreshing the page
    event.preventDefault();
  });
}

function initPravnickaFormProcessingHandler() {
  $('#form-pravnicka').submit(function(event) {

    // get the form data
    // there are many ways to get this data using jQuery (you can use the class or id also)
    let formData = {
      'type': 'pravnicka',
      'pravnickaName': $('input[name=pravnickaName]').val(),
      'pravnickaWeb': $('input[name=pravnickaWeb]').val(),
      'pravnickaZastupce': $('input[name=pravnickaZastupce]').val(),
      'pravnickaEmailZastupce': $('input[name=pravnickaEmailZastupce]').val(),
      'gdprSouhlas': $('input[name=gdprSouhlas]:checked')[0]?.checked || false,
      'newsletter': $('input[name=newsletter]:checked')[0]?.checked || false,
      'token': $('#pravnickaToken').val(),
      'action': $('#pravnickaAction').val(),
    };

    console.log(formData)

    // process the form
    $.ajax({
      type        : 'POST', // define the type of HTTP verb we want to use (POST for our form)
      url         : 'formProcess.php', // the url where we want to POST
      data        : formData, // our data object
      dataType    : 'json', // what type of data do we expect back from the server
      encode          : true
    })
      .done(function(data) {
        console.log(data);
        refreshPetitionSignaturesCount()
        $('form').trigger("reset")
        showSuccessAlert()
      })
      .fail(function(data) {
        console.log(data)
        $('form').html('<div class="alert alert-danger">Nastala chyba při spojení se serverem. Zkuste to prosím později.</div>');
      })

    // stop the form from submitting the normal way and refreshing the page
    event.preventDefault();
  });
}

function refreshPetitionSignaturesCount() {
  $.get('getSigCountF.php', (res) => {
    if (res.length < 30) $('#sigCountF').text(res)
  })
  $.get('getSigCountP.php', (res) => {
    if (res.length < 30) $('#sigCountP').text(res)
  })

}

function showSuccessAlert() {
  $('#signatureSuccess').show(1000)

  // setTimeout(() => {
  //   $('#signatureSuccess').hide(1000)
  // }, 7000)
}

function initSharingBtns() {
  $('#shareFb').click(shareToFacebook)
  $('#shareTwitter').click(shareToTwitter)
}

function banana() {
  console.log('banana')
}

function initFormRadioSwitches() {
  $('#radio-fyzicka').click(() => {
    $('#form-pravnicka').hide()
    $('#form-fyzicka').show()
  })
  $('#radio-pravnicka').click(() => {
    $('#form-fyzicka').hide()
    $('#form-pravnicka').show()
  })
}

function initFyzickaCheckboxes() {
  $('#sPostizenim').on('change', (event) => {
    if (event.target.checked) {
      $('#pecujici')[0].checked = false
      $('#verejnost')[0].checked = false
    }
  })
  $('#pecujici').on('change', (event) => {
    if (event.target.checked) {
      $('#sPostizenim')[0].checked = false
      $('#verejnost')[0].checked = false
    }
  })
  $('#verejnost').on('change', (event) => {
    if (event.target.checked) {
      $('#sPostizenim')[0].checked = false
      $('#pecujici')[0].checked = false
    }
  })
}

$(document).ready(function() {
  refreshPetitionSignaturesCount()
  initPravnickaFormProcessingHandler()
  initFyzickaFormProcessingHandler()
  initSharingBtns()
  initFormRadioSwitches()
  initFyzickaCheckboxes()
});
